:root {
  --bit: 1.5625vw;
  --cell: 3.125vw;
  --line: 1.5rem;
  --rule: 1px;
  --w-thin: 0;
  --w-neat: 0;
  --adj_cell: calc(3.125vw - 1px);
  --adj_bit: calc(1.5625vw - 1px);
  --border-color: #d0d3d4;
}


body {
  font-family: ui-sans-serif, sans-serif, system-ui;
}
@supports (object-fit: cover) {
  .fit-img img {
    -o-object-fit: cover;
    object-fit: cover;
  }
}

[class*="grid_"] {
  --w-1-20: 1fr;
  --w-2-20: 4fr;
  --w-3-20: 7fr;
  --w-4-20: 10fr;
  --w-5-20: 13fr;
  --w-6-20: 16fr;
  --w-7-20: 19fr;
  --w-8-20: 22fr;
  --w-9-20: 25fr;
  --w-10-20: 28fr;
  --w-11-20: 31fr;
  --w-12-20: 34fr;
  --w-13-20: 37fr;
  --w-14-20: 40fr;
  --w-15-20: 43fr;
  --w-16-20: 46fr;
  --w-17-20: 49fr;
  --w-18-20: 52fr;
  --w-19-20: 55fr;
  --w-20-20: 58fr;

  --w-1-24: 0.5fr;
  --w-2-24: 3fr;
  --w-3-24: 5.5fr;
  --w-4-24: 8fr;
  --w-5-24: 10.5fr;
  --w-6-24: 13fr;
  --w-7-24: 15.5fr;
  --w-8-24: 18fr;
  --w-9-24: 20.5fr;
  --w-10-24: 23fr;
  --w-11-24: 25.5fr;
  --w-12-24: 28fr;
  --w-13-24: 30.5fr;
  --w-14-24: 33fr;
  --w-15-24: 35.5fr;
  --w-16-24: 38fr;
  --w-17-24: 40.5fr;
  --w-18-24: 43fr;
  --w-19-24: 45.5fr;
  --w-20-24: 48fr;
  --w-21-24: 50.5fr;
  --w-22-24: 53fr;
  --w-23-24: 55.5fr;
  --w-24-24: 58fr;
}

[class*="grid_"][class*="--thin"] {
  --w-1-20: 2fr;
  --w-2-20: 5fr;
  --w-3-20: 8fr;
  --w-4-20: 11fr;
  --w-5-20: 14fr;
  --w-6-20: 17fr;
  --w-7-20: 20fr;
  --w-8-20: 23fr;
  --w-9-20: 26fr;
  --w-10-20: 29fr;
  --w-11-20: 32fr;
  --w-12-20: 35fr;
  --w-13-20: 38fr;
  --w-14-20: 41fr;
  --w-15-20: 44fr;
  --w-16-20: 47fr;
  --w-17-20: 50fr;
  --w-18-20: 53fr;
  --w-19-20: 56fr;
  --w-20-20: 59fr;

  --w-1-24: 1.5fr;
  --w-2-24: 4fr;
  --w-3-24: 6.5fr;
  --w-4-24: 9fr;
  --w-5-24: 11.5fr;
  --w-6-24: 14fr;
  --w-7-24: 16.5fr;
  --w-8-24: 19fr;
  --w-9-24: 21.5fr;
  --w-10-24: 24fr;
  --w-11-24: 26.5fr;
  --w-12-24: 29fr;
  --w-13-24: 31.5fr;
  --w-14-24: 34fr;
  --w-15-24: 36.5fr;
  --w-16-24: 39fr;
  --w-17-24: 41.5fr;
  --w-18-24: 44fr;
  --w-19-24: 46.5fr;
  --w-20-24: 49fr;
  --w-21-24: 51.5fr;
  --w-22-24: 54fr;
  --w-23-24: 56.5fr;
  --w-24-24: 59fr;
}
[class*="grid_"][class*="--rule"],
[class*="grid_"][class*="--neat"] {
  --w-1-20: 3fr;
  --w-2-20: 6fr;
  --w-3-20: 9fr;
  --w-4-20: 12fr;
  --w-5-20: 15fr;
  --w-6-20: 18fr;
  --w-7-20: 21fr;
  --w-8-20: 24fr;
  --w-9-20: 27fr;
  --w-10-20: 30fr;
  --w-11-20: 33fr;
  --w-12-20: 36fr;
  --w-13-20: 39fr;
  --w-14-20: 42fr;
  --w-15-20: 45fr;
  --w-16-20: 48fr;
  --w-17-20: 51fr;
  --w-18-20: 54fr;
  --w-19-20: 57fr;
  --w-20-20: 60fr;

  --w-1-24: 2.5fr;
  --w-2-24: 5fr;
  --w-3-24: 7.5fr;
  --w-4-24: 10fr;
  --w-5-24: 12.5fr;
  --w-6-24: 15fr;
  --w-7-24: 17.5fr;
  --w-8-24: 20fr;
  --w-9-24: 22.5fr;
  --w-10-24: 25fr;
  --w-11-24: 27.5fr;
  --w-12-24: 30fr;
  --w-13-24: 32.5fr;
  --w-14-24: 35fr;
  --w-15-24: 37.5fr;
  --w-16-24: 40fr;
  --w-17-24: 42.5fr;
  --w-18-24: 45fr;
  --w-19-24: 47.5fr;
  --w-20-24: 40fr;
  --w-21-24: 52.5fr;
  --w-22-24: 55fr;
  --w-23-24: 57.5fr;
  --w-24-24: 60fr;
}

@media screen and (min-width: 30rem) {
  [class*="grid_0"] {
    width: 100%;
    display: grid;
    column-gap: var(--cell);
    margin-left: 0;
    margin-right: 0;
    padding-left: 4.6875vw;
    padding-right: 4.6875vw;
    grid-row-gap: 0;
  }
  [class*="--thin"] {
    padding-left: var(--cell);
    column-gap: var(--bit);
  }
  [class*="--neat"],
  [class*="--rule"] {
    padding-left: var(--cell);
    padding-right: var(--cell);
    column-gap: 0;
  }
  [class*="--rule"] > *,
  [class*="--thin"][class*="pad"] > *,
  [class*="--neat"][class*="pad"] > * {
    padding-left: var(--bit);
    padding-right: var(--bit);
  }
  .rowgap--cell,
  .row-gap--cell {
    grid-row-gap: var(--cell);
  }
  .rowgap--line,
  .row-gap--line {
    grid-row-gap: var(--line);
  }
  .grid_02,
  .grid_02a,
  .grid_02b {
    grid-template-columns: 1fr 1fr;
  }
  .grid_02a--01 {
    grid-template-columns: var(--w-14-24) var(--w-10-24);
  }
  .grid_02a--02 {
    grid-template-columns: var(--w-12-20) var(--w-8-20);
  }
  .grid_02a--03 {
    grid-template-columns: var(--w-16-24) var(--w-8-24);
  }
  .grid_02a--04 {
    grid-template-columns: var(--w-14-20) var(--w-6-20);
  }
  .grid_02a--05 {
    grid-template-columns: var(--w-18-24) var(--w-6-24);
  }
  .grid_02a--06 {
    grid-template-columns: var(--w-16-20) var(--w-4-20);
  }
  .grid_02b--01 {
    grid-template-columns: var(--w-10-24) var(--w-14-24);
  }
  .grid_02b--02 {
    grid-template-columns: var(--w-8-20) var(--w-12-20);
  }
  .grid_02b--03 {
    grid-template-columns: var(--w-8-24) var(--w-16-24);
  }
  .grid_02b--04 {
    grid-template-columns: var(--w-6-20) var(--w-14-20);
  }
  .grid_02b--05 {
    grid-template-columns: var(--w-6-24) var(--w-18-24);
  }
  .grid_02b--06 {
    grid-template-columns: var(--w-4-20) var(--w-16-20);
  }
  .grid_03,
  .grid_03a,
  .grid_03b,
  .grid_03c {
    grid-template-columns: repeat(3, 1fr);
  }
  .grid_03a {
    grid-template-columns: var(--w-10-24) var(--w-7-24) var(--w-7-24);
  }
  .grid_03a--01 {
    grid-template-columns: var(--w-10-24) var(--w-8-24) var(--w-6-24);
  }
  .grid_03a--02 {
    grid-template-columns: var(--w-10-20) var(--w-6-20) var(--w-4-20);
  }
  .grid_03a--03 {
    grid-template-columns: var(--w-12-24) var(--w-8-24) var(--w-4-24);
  }
  .grid_03a--04 {
    grid-template-columns: var(--w-14-24) var(--w-6-24) var(--w-4-24);
  }
  .grid_03a--05 {
    grid-template-columns: var(--w-8-20) var(--w-6-20) var(--w-6-20);
  }
  .grid_03a--06 {
    grid-template-columns: var(--w-12-24) var(--w-6-24) var(--w-6-24);
  }
  .grid_03a--07 {
    grid-template-columns: var(--w-12-20) var(--w-4-20) var(--w-4-20);
  }
  .grid_03a--08 {
    grid-template-columns: var(--w-16-24) var(--w-4-24) var(--w-4-24);
  }
  .grid_03b {
    grid-template-columns: var(--w-7-24) var(--w-7-24) var(--w-10-24);
  }

  .grid_03b--01 {
    grid-template-columns: var(--w-6-24) var(--w-8-24) var(--w-10-24);
  }
  .grid_03b--02 {
    grid-template-columns: var(--w-4-20) var(--w-6-20) var(--w-10-20);
  }
  .grid_03b--03 {
    grid-template-columns: var(--w-4-24) var(--w-8-24) var(--w-12-24);
  }
  .grid_03b--04 {
    grid-template-columns: var(--w-4-24) var(--w-6-24) var(--w-14-24);
  }
  .grid_03b--05 {
    grid-template-columns: var(--w-6-20) var(--w-6-20) var(--w-8-20);
  }
  .grid_03b--06 {
    grid-template-columns: var(--w-6-24) var(--w-6-24) var(--w-12-24);
  }
  .grid_03b--07 {
    grid-template-columns: var(--w-4-20) var(--w-4-20) var(--w-12-20);
  }
  .grid_03b--08 {
    grid-template-columns: var(--w-4-24) var(--w-4-24) var(--w-16-24);
  }
  .grid_03c, 
  .grid_03d {
    grid-template-columns: var(--w-7-24) var(--w-10-24) var(--w-7-24);
  }
  .grid_03c--01 {
    grid-template-columns: var(--w-8-24) var(--w-10-24) var(--w-6-24);
  }
  .grid_03c--02 {
    grid-template-columns: var(--w-6-20) var(--w-10-20) var(--w-4-20);
  }
  .grid_03c--03 {
    grid-template-columns: var(--w-8-24) var(--w-12-24) var(--w-4-24);
  }
  .grid_03c--04 {
    grid-template-columns: var(--w-6-24) var(--w-14-24) var(--w-4-24);
  }
  .grid_03c--05,
  .grid_03d--05 {
    grid-template-columns: var(--w-6-20) var(--w-8-20) var(--w-6-20);
  }
  .grid_03c--06,
  .grid_03d--06 {
    grid-template-columns: var(--w-6-24) var(--w-12-24) var(--w-6-24);
  }
  .grid_03c--07,
  .grid_03d--07 {
    grid-template-columns: var(--w-4-20) var(--w-12-20) var(--w-4-20);
  }
  .grid_03c--08,
  .grid_03d--08 {
    grid-template-columns: var(--w-4-24) var(--w-16-24) var(--w-4-24);
  }

  .grid_03d--01 {
    grid-template-columns: var(--w-6-24) var(--w-10-24) var(--w-8-24);
  }
  .grid_03d--02 {
    grid-template-columns: var(--w-4-20) var(--w-10-20) var(--w-6-20);
  }
  .grid_03d--03 {
    grid-template-columns: var(--w-4-24) var(--w-12-24) var(--w-8-24);
  }
  .grid_03d--04 {
    grid-template-columns: var(--w-4-24) var(--w-14-24) var(--w-6-24);
  }

  .grid_04,
  .grid_04a,
  .grid_04b,
  .grid_04c {
    grid-template-columns: repeat(4, 1fr);
  }
  .grid_04a--01 {
    grid-template-columns: var(--w-8-24) var(--w-6-24) var(--w-6-24) var(
        --w-4-24
      );
  }
  .grid_04a--02 {
    grid-template-columns: var(--w-7-20) var(--w-5-20) var(--w-4-20) var(
        --w-4-20
      );
  }
  .grid_04a--03 {
    grid-template-columns: var(--w-10-24) var(--w-6-24) var(--w-4-24) var(
        --w-4-24
      );
  }
  .grid_04a--04 {
    grid-template-columns: var(--w-8-20) var(--w-6-20) var(--w-3-20) var(
        --w-3-20
      );
  }

  .grid_04b--01 {
    grid-template-columns: var(--w-4-24) var(--w-6-24) var(--w-8-24) var(
        --w-6-24
      );
  }
  .grid_04b--02 {
    grid-template-columns: var(--w-4-20) var(--w-4-20) var(--w-7-20) var(
        --w-5-20
      );
  }
  .grid_04b--03 {
    grid-template-columns: var(--w-4-24) var(--w-4-24) var(--w-10-24) var(
        --w-6-24
      );
  }
  .grid_04b--04 {
    grid-template-columns: var(--w-3-20) var(--w-3-20) var(--w-8-20) var(
        --w-6-20
      );
  }

  .grid_04c--01 {
    grid-template-columns: var(--w-6-24) var(--w-8-24) var(--w-6-24) var(
        --w-4-24
      );
  }
  .grid_04c--02 {
    grid-template-columns: var(--w-5-20) var(--w-7-20) var(--w-4-20) var(
        --w-4-20
      );
  }
  .grid_04c--03 {
    grid-template-columns: var(--w-6-24) var(--w-10-24) var(--w-4-24) var(
        --w-4-24
      );
  }
  .grid_04c--04 {
    grid-template-columns: var(--w-6-20) var(--w-8-20) var(--w-3-20) var(
        --w-3-20
      );
  }

  .grid_04d--01 {
    grid-template-columns: var(--w-6-24) var(--w-6-24) var(--w-8-24) var(
        --w-4-24
      );
  }
  .grid_04d--02 {
    grid-template-columns: var(--w-4-20) var(--w-5-20) var(--w-7-20) var(
        --w-4-20
      );
  }
  .grid_04d--03 {
    grid-template-columns: var(--w-4-24) var(--w-6-24) var(--w-10-24) var(
        --w-4-24
      );
  }
  .grid_04d--04 {
    grid-template-columns: var(--w-3-20) var(--w-6-20) var(--w-8-20) var(
        --w-3-20
      );
  }
}

@media screen and (min-width: 30rem) and (max-width: 59.9999999999rem) {
  [class*="grid_"][class*="flow_"]:not([class*="set_"]),
  [class*="grid_"][class*="display-block-on"] {
    display: block;
  }
  [class*="grid_02"]:not([class*="flow_"]):not([class*="set_"]),
  .set_03,
  .set_05,
  .set_06:not([class*="flow_"]) {
    grid-template-columns: repeat(2, var(--w-12-24));
  }
  .set_04 {
    grid-template-columns: repeat(3, var(--w-8-24));
  }
  [class*="flow_"].set_06 {
    grid-template-columns: repeat(6, var(--w-4-24));
  }
  [class*="set_"] > :first-child {
    grid-column: 1/-1;
  }
  .set_06:not([class*="flow_"]) > :first-child {
    grid-column: 1/2;
  }
  [class*="flow_"].set_06 > :nth-child(n + 2):nth-child(-n + 3) {
    grid-column: span 3;
  }
  [class*="flow_"].set_06 > :nth-child(n + 4) {
    grid-column: span 2;
  }
}

@media screen and (min-width: 60rem) {
  /* General */
  [class*="grid_02"] > :nth-child(-n + 2),
  [class*="grid_03"] > :nth-child(-n + 3),
  [class*="grid_04"] > :nth-child(-n + 4) {
    grid-row: 1/2;
  }
  /* grid_02 + flow (no set) */
  [class*="grid_02b"].flow_01 > :first-child {
    grid-column: 2/3;
  }
  /* grid + set (no flow) */
  [class*="grid_02"].set_03 > :first-child {
    grid-row: 1/3;
  }
  [class*="grid_02"].set_04 > :first-child {
    grid-row: 1/4;
  }
  [class*="grid_02"].set_05 > :first-child {
    grid-row: 1/5;
  }
  /* .grid[set].flow_01 */
  [class*="grid_02a"][class*="set_"].flow_01 > :nth-child(n + 2),
  [class*="grid_02b"][class*="set_"].flow_01 > :first-child {
    grid-column: 1/2;
  }
  [class*="grid_02a"][class*="set_"].flow_01 > :first-child,
  [class*="grid_02b"][class*="set_"].flow_01 > :nth-child(n + 2) {
    grid-column: 2/3;
  }
  /* [grid] + set + flow */
  [class*="grid_02"].set_03.flow_02 > :first-child,
  [class*="grid_02"].set_03.flow_03 > :first-child,
  [class*="grid_02"].set_05.flow_02 > :first-child,
  [class*="grid_02"].set_05.flow_03 > :first-child {
    grid-column: 1/-1;
    grid-row: 1/2;
  }

  [class*="grid_02"].set_03.flow_04 > :first-child,
  [class*="grid_02"].set_03.flow_05 > :first-child,
  [class*="grid_02"].set_04.flow_02 > :first-child {
    grid-row: 1/2;
  }
  [class*="grid_02"].set_03.flow_04 > :nth-child(3),
  [class*="grid_02"].set_03.flow_05 > :nth-child(3) {
    grid-row: 1/3;
  }
  [class*="grid_02"].set_04.flow_02 > :last-child {
    grid-row: 1/4;
  }

  [class*="grid_02"].set_03.flow_02 > :nth-child(n + 2),
  [class*="grid_02"].set_03.flow_03 > :nth-child(n + 2),
  [class*="grid_02"].set_03.flow_04 > :nth-child(2),
  [class*="grid_02"].set_03.flow_05 > :nth-child(2),
  [class*="grid_02"].set_04.flow_02 > :nth-child(2),
  [class*="grid_02"].set_05.flow_02 > :nth-child(n + 2):nth-child(-n + 3),
  [class*="grid_02"].set_05.flow_03 > :nth-child(n + 2):nth-child(-n + 3) {
    grid-row: 2/3;
  }
  [class*="grid_02"].set_04.flow_02 > :nth-child(3),
  [class*="grid_02b"].set_05.flow_03 > :nth-child(4) {
    grid-row: 3/4;
  }
  /* [grid_02].set_05.flow_04 */
  [class*="grid_02"].set_05.flow_04 > :first-child {
    grid-column: 1/2;
    grid-row: 1/4;
  }
  [class*="grid_02"].set_05.flow_04 > :nth-child(2) {
    grid-column: 1/2;
    grid-row: 4/7;
  }
  [class*="grid_02"].set_05.flow_04 > :nth-child(3) {
    grid-column: 2/3;
    grid-row: 1/3;
  }
  [class*="grid_02"].set_05.flow_04 > :nth-child(4) {
    grid-column: 2/3;
    grid-row: 3/5;
  }
  [class*="grid_02"].set_05.flow_04 > :nth-child(5) {
    grid-column: 2/3;
    grid-row: 5/7;
  }
  /* [grid_02].set_05.flow_05 */
  [class*="grid_02"].set_05.flow_05 > :first-child {
    grid-column: 2/3;
    grid-row: 1/4;
  }
  [class*="grid_02"].set_05.flow_05 > :nth-child(2) {
    grid-column: 2/3;
    grid-row: 4/7;
  }
  [class*="grid_02"].set_05.flow_05 > :nth-child(3) {
    grid-column: 1/2;
    grid-row: 1/3;
  }
  [class*="grid_02"].set_05.flow_05 > :nth-child(4) {
    grid-column: 1/2;
    grid-row: 3/5;
  }
  [class*="grid_02"].set_05.flow_05 > :nth-child(5) {
    grid-column: 1/2;
    grid-row: 5/7;
  }
  /* [grid_02].set_05.flow_06 */
  [class*="grid_02"].set_05.flow_06 > :first-child {
    grid-column: 1/2;
    grid-row: 1/3;
  }
  [class*="grid_02"].set_05.flow_06 > :nth-child(2) {
    grid-column: 1/2;
    grid-row: 3/5;
  }
  [class*="grid_02"].set_05.flow_06 > :nth-child(3) {
    grid-column: 1/2;
    grid-row: 5/7;
  }
  [class*="grid_02"].set_05.flow_06 > :nth-child(4) {
    grid-column: 2/3;
    grid-row: 1/4;
  }
  [class*="grid_02"].set_05.flow_06 > :nth-child(5) {
    grid-column: 2/3;
    grid-row: 4/7;
  }
  /* [grid_02].set_05.flow_07 */
  [class*="grid_02"].set_05.flow_07 > :first-child {
    grid-column: 2/3;
    grid-row: 1/3;
  }
  [class*="grid_02"].set_05.flow_07 > :nth-child(2) {
    grid-column: 2/3;
    grid-row: 3/5;
  }
  [class*="grid_02"].set_05.flow_07 > :nth-child(3) {
    grid-column: 2/3;
    grid-row: 5/7;
  }
  [class*="grid_02"].set_05.flow_07 > :nth-child(4) {
    grid-column: 1/2;
    grid-row: 1/4;
  }
  [class*="grid_02"].set_05.flow_07 > :nth-child(5) {
    grid-column: 1/2;
    grid-row: 4/7;
  }

  /* 02 .grid .set .flow */

  [class*="grid_02b"].set_03.flow_03 > :nth-child(3),
  [class*="grid_02a"].set_03.flow_04 > :nth-child(2),
  [class*="grid_02b"].set_03.flow_05 > :nth-child(3),
  [class*="grid_02a"].set_04.flow_02 > :nth-child(n + 1):nth-child(-n + 3) {
    grid-column: 1/2;
  }
  [class*="grid_02a"].set_03.flow_04 > :nth-child(3),
  [class*="grid_02b"].set_03.flow_05 > :nth-child(n + 1):nth-child(-n + 2),
  [class*="grid_02b"].set_04.flow_02 > :nth-child(n + 1):nth-child(-n + 3),
  [class*="grid_02b"].set_05.flow_03 > :nth-child(2n) {
    grid-column: 2/3;
  }

  /* grid_03.set_03, grid_03.set_03.flow_01 */

  [class*="grid_03b"].set_03 > :nth-child(3),
  [class*="grid_03c"].set_03.flow_01 > :first-child,
  [class*="grid_03d"].set_03.flow_01 > :first-child {
    grid-column: 1/2;
  }
  [class*="grid_03b"].set_03 > :nth-child(2),
  [class*="grid_03c"].set_03 > :first-child,
  [class*="grid_03d"].set_03 > :first-child,
  [class*="grid_03a"].set_03.flow_01 > :nth-child(2) {
    grid-column: 2/3;
  }
  [class*="grid_03b"].set_03 > :first-child,
  [class*="grid_03d"].set_03
    > :nth-child(2)
    [class*="grid_03a"].set_03.flow_01
    > :first-child,
  [class*="grid_03c"].set_03.flow_01 > :nth-child(2),
  [class*="grid_03d"].set_03.flow_01 > :nth-child(2) {
    grid-column: 3/4;
  }

  /* grid_03.set_04 */
  [class*="grid_03"].set_04 > :first-child {
    grid-column: 1/4;
  }
  [class*="grid_03"].set_04 > :nth-child(n + 2):nth-child(-n + 4) {
    grid-row: 2/3;
  }
  /* [grid_03].set_04.[flow] */
  [class*="grid_03"][class*="flow"].set_04:not([class*="flow_01"])
    > :first-child {
    grid-column: 2/3;
  }
  /* Overrides for :first-child */
  [class*="grid_03"][class*="flow"]:not([class*="03b"]):not([class*="03c"]):not([class*="03d"]).set_04:not([class*="flow_01"])
    > :first-child {
    grid-column: 1/2;
  }
  [class*="grid_03b"][class*="flow"].set_04:not([class*="flow_01"])
    > :first-child {
    grid-column: 3/4;
  }
  /* [grid_03].set_04.flow */
  [class*="grid_03"].set_04.flow_02 > :first-child,
  [class*="grid_03"].set_04.flow_03 > :first-child,
  [class*="grid_03"].set_04.flow_04 > :nth-child(n + 1):nth-child(-n + 2),
  [class*="grid_03"].set_04.flow_05 > :nth-child(n + 1):nth-child(-n + 2),
  [class*="grid_03"].set_04.flow_06 > :nth-child(n + 3):nth-child(-n + 4) {
    grid-row: 1/3;
  }
  [class*="grid_03"].set_04.flow_02 > :nth-child(2),
  [class*="grid_03"].set_04.flow_03 > :nth-child(2),
  [class*="grid_03"].set_04.flow_04 > :nth-child(3),
  [class*="grid_03"].set_04.flow_05 > :nth-child(3) {
    grid-row: 1/2;
  }
  [class*="grid_03"].set_04.flow_02 > :nth-child(3),
  [class*="grid_03"].set_04.flow_03 > :nth-child(3),
  [class*="grid_03"].set_04.flow_04 > :nth-child(4),
  [class*="grid_03"].set_04.flow_05 > :nth-child(4) {
    grid-row: 2/3;
  }
  [class*="grid_03"].set_04.flow_02 > :nth-child(4),
  [class*="grid_03"].set_04.flow_03 > :nth-child(4) {
    grid-row: 1/3;
  }

  /* .grid_03*.set_04.flow */
  [class*="grid_03b"].set_04.flow_01 > :nth-child(3),
  [class*="grid_03c"].set_04.flow_01 > :nth-child(2),
  [class*="grid_03d"].set_04.flow_01 > :nth-child(2),
  [class*="grid_03b"].set_04.flow_05 > :nth-child(2),
  [class*="grid_03c"].set_04.flow_06 > :nth-child(2),
  [class*="grid_03d"].set_04.flow_06 > :nth-child(2) {
    grid-column: 2/3;
  }
  [class*="grid_03b"].set_04.flow_01 > :nth-child(2),
  [class*="grid_03d"].set_04.flow_01 > :nth-child(3),
  [class*="grid_03d"].set_04.flow_05 > :nth-child(2),
  [class*="grid_03b"].set_04.flow_06 > :nth-child(2) {
    grid-column: 3/4;
  }
  [class*="grid_03b"].set_04.flow_03 > :last-child,
  [class*="grid_03d"].set_04.flow_03 > :last-child {
    grid-column: 1/2;
  }

  /* [grid_03].set_05 */
  [class*="grid_03"].set_05 > :first-child {
    grid-row: 1/3;
  }
  [class*="grid_03"].set_05 > :nth-child(3) {
    grid-column: 2/3;
    grid-row: 2/3;
  }
  /* [grid_03].set_05.flow */
  [class*="grid_03"].set_05.flow_02 > :nth-child(odd),
  [class*="grid_03"].set_05.flow_03 > :first-child {
    grid-row: 1/2;
  }
  [class*="grid_03"].set_05.flow_02 > :nth-child(even),
  [class*="grid_03"].set_05.flow_03 > :nth-child(2),
  [class*="grid_03"].set_05.flow_04 > :nth-child(4) {
    grid-row: 2/3;
  }
  [class*="grid_03"].set_05.flow_03 > :nth-child(3) {
    grid-row: 3/4;
  }
  [class*="grid_03"].set_05.flow_02 > :last-child {
    grid-row: 1/3;
  }
  [class*="grid_03"].set_05.flow_03 > :nth-child(n + 4):nth-child(-n + 5) {
    grid-row: 1/4;
  }
  /* grid_03.set_05[flow] */
  [class*="grid_03b"][class*="flow_"].set_05 > :first-child {
    grid-column: 3/4;
  }
  [class*="grid_03c"][class*="flow_"].set_05 > :first-child,
  [class*="grid_03d"][class*="flow_"].set_05 > :first-child {
    grid-column: 2/3;
  }
  /* .grid_03*.set_05.flow */
  .grid_03.set_05.flow_04 > :first-child,
  [class*="grid_03a"].set_05.flow_04 > :first-child,
  [class*="grid_03b"].set_05.flow_04 > :nth-child(3) {
    grid-row: 1/2;
  }
  .grid_03.set_05.flow_04 > :nth-child(2),
  [class*="grid_03a"].set_05.flow_04 > :nth-child(2),
  [class*="grid_03b"].set_05.flow_04 > :nth-child(4) {
    grid-row: 2/3;
  }
  .grid_03.set_05.flow_04 > :nth-child(3),
  [class*="grid_03a"].set_05.flow_04 > :nth-child(3),
  [class*="grid_03b"].set_05.flow_04 > :nth-child(5) {
    grid-row: 3/4;
  }
  .grid_03.set_05.flow_04 > :nth-child(n + 4):nth-child(-n + 5),
  [class*="grid_03a"].set_05.flow_04 > :nth-child(n + 4):nth-child(-n + 5) {
    grid-row: 1/4;
  }

  [class*="grid_03b"].set_05.flow_02 > :last-child,
  [class*="grid_03c"].set_05.flow_01 > :nth-child(3),
  [class*="grid_03c"].set_05.flow_02 > :nth-child(3),
  [class*="grid_03d"].set_05.flow_02 > :nth-child(5),
  [class*="grid_03"].set_05.flow_03 > :nth-child(-n + 3),
  .grid_03.set_05.flow_04 > :nth-child(5),
  [class*="grid_03a"].set_05.flow_04 > :nth-child(5) {
    grid-column: 1/2;
  }
  [class*="grid_03b"].set_05.flow_01 > :nth-child(2),
  [class*="grid_03c"].set_05.flow_02 > :nth-child(2),
  [class*="grid_03d"].set_05.flow_02 > :nth-child(2),
  [class*="grid_03b"].set_05.flow_03 > :nth-child(4),
  [class*="grid_03c"].set_05.flow_03 > :nth-child(-n + 3),
  [class*="grid_03d"].set_05.flow_03 > :nth-child(-n + 3),
  .grid_03.set_05.flow_04 > :nth-child(4),
  [class*="grid_03a"].set_05.flow_04 > :nth-child(4) {
    grid-column: 2/3;
  }
  [class*="grid_03d"].set_05.flow_01 > :nth-child(n + 2):nth-child(-n + 3),
  [class*="grid_03b"].set_05.flow_02 > :nth-child(2),
  [class*="grid_03d"].set_05.flow_02 > :nth-child(3),
  [class*="grid_03b"].set_05.flow_03 > :nth-child(-n + 3),
  .grid_03.set_05.flow_04 > :nth-child(-n + 3),
  [class*="grid_03a"].set_05.flow_04 > :nth-child(-n + 3),
  [class*="grid_03b"].set_05.flow_04 > :nth-child(n + 3):nth-child(-n + 5) {
    grid-column: 3/4;
  }
  [class*="grid_03b"].set_05.flow_04 > :first-child {
    grid-column: 1/2;
    grid-row: 1/4;
  }
  [class*="grid_03b"].set_05.flow_04 > :nth-child(2) {
    grid-column: 2/3;
    grid-row: 1/4;
  }
  [class*="grid_03c"].set_05.flow_04 > :first-child,
  [class*="grid_03d"].set_05.flow_04 > :first-child {
    grid-column: 1/2;
    grid-row: 1/4;
  }
  [class*="grid_03c"].set_05.flow_04 > :last-child,
  [class*="grid_03d"].set_05.flow_04 > :last-child {
    grid-column: 3/4;
    grid-row: 1/4;
  }
  [class*="grid_03c"].set_05.flow_04 > :nth-child(4),
  [class*="grid_03d"].set_05.flow_04 > :nth-child(4) {
    grid-column: 2/3;
    grid-row: 3/4;
  }
  /* [grid_03].set_06 */
  [class*="grid_03"].set_06:not([class*="flow_"]) > :first-child,
  [class*="grid_03"][class*="flow_"].set_06 > :first-child {
    grid-row: 1/7;
  }
  [class*="grid_03"].set_06.flow_03:not([class*="03c"]):not([class*="03d"]) > :not(:nth-child(n+2):nth-child(-n+5)),
  [class*="grid_03"].set_06.flow_04:not([class*="03c"]):not([class*="03d"]) > :nth-child(n+1):nth-child(-n+2) {
    grid-row: 1/9;
  }
  [class*="grid_03"].set_06:not([class*="flow_"]) > :nth-child(2),
  [class*="grid_03"].set_06.flow_01 > :nth-child(2),
  [class*="grid_03"].set_06.flow_02 > :nth-child(5),
  [class*="grid_03c"].set_06.flow_03 > :nth-child(2), 
  [class*="grid_03d"].set_06.flow_03 > :nth-child(2),
  [class*="grid_03c"].set_06.flow_04 > :nth-child(5),
  [class*="grid_03d"].set_06.flow_04 > :nth-child(5) {
    grid-row: 1/4;
  }
  [class*="grid_03"].set_06:not([class*="flow_"]) > :nth-child(3),
  [class*="grid_03"].set_06.flow_01 > :nth-child(3),
  [class*="grid_03"].set_06.flow_02 > :nth-child(6),
  [class*="grid_03c"].set_06.flow_03 > :nth-child(3),
  [class*="grid_03d"].set_06.flow_03 > :nth-child(3),
  [class*="grid_03c"].set_06.flow_04 > :nth-child(6),
  [class*="grid_03d"].set_06.flow_04 > :nth-child(6) {
    grid-row: 4/7;
  }
  [class*="grid_03"].set_06:not([class*="flow_"]) > :nth-child(4),
  [class*="grid_03"].set_06.flow_01 > :nth-child(4),
  [class*="grid_03"].set_06.flow_02 > :nth-child(2),
  [class*="grid_03c"].set_06.flow_03 > :nth-child(4),
  [class*="grid_03d"].set_06.flow_03 > :nth-child(4),
  [class*="grid_03c"].set_06.flow_04 > :nth-child(2),
  [class*="grid_03d"].set_06.flow_04 > :nth-child(2) {
    grid-row: 1/3;
  }
  [class*="grid_03"].set_06:not([class*="flow_"]) > :nth-child(5),
  [class*="grid_03"].set_06.flow_01 > :nth-child(5),
  [class*="grid_03"].set_06.flow_02 > :nth-child(3),
  [class*="grid_03c"].set_06.flow_03 > :nth-child(5),
  [class*="grid_03d"].set_06.flow_03 > :nth-child(5),
  [class*="grid_03c"].set_06.flow_04 > :nth-child(3),
  [class*="grid_03d"].set_06.flow_04 > :nth-child(3) {
    grid-row: 3/5;
  }
  [class*="grid_03"].set_06:not([class*="flow_"]) > :nth-child(6),
  [class*="grid_03"].set_06.flow_01 > :nth-child(6),
  [class*="grid_03"].set_06.flow_02 > :nth-child(4),
  [class*="grid_03c"].set_06.flow_03 > :nth-child(6),
  [class*="grid_03d"].set_06.flow_03 > :nth-child(6),
  [class*="grid_03c"].set_06.flow_04 > :nth-child(4),
  [class*="grid_03d"].set_06.flow_04 > :nth-child(4) {
    grid-row: 5/7;
  }

  [class*="grid_03"].set_06.flow_03:not([class*="03c"]):not([class*="03d"]) > :nth-child(2),
  [class*="grid_03"].set_06.flow_04:not([class*="03c"]):not([class*="03d"]) > :nth-child(3) {
    grid-row: 1/3;
  }
  [class*="grid_03"].set_06.flow_03:not([class*="03c"]):not([class*="03d"]) > :nth-child(3),
  [class*="grid_03"].set_06.flow_04:not([class*="03c"]):not([class*="03d"]) > :nth-child(4) {
    grid-row: 3/5;
  }
  [class*="grid_03"].set_06.flow_03:not([class*="03c"]):not([class*="03d"]) > :nth-child(4),
  [class*="grid_03"].set_06.flow_04:not([class*="03c"]):not([class*="03d"]) > :nth-child(5) {
    grid-row: 5/7;
  }
  [class*="grid_03"].set_06.flow_03:not([class*="03c"]):not([class*="03d"]) > :nth-child(5),
  [class*="grid_03"].set_06.flow_04:not([class*="03c"]):not([class*="03d"]) > :nth-child(6) {
    grid-row: 7/9;
  }
  /* grid_03.set_06.flow */
  [class*="grid_03c"].set_06.flow_03 > :nth-child(n + 4):nth-child(-n + 6),
  [class*="grid_03c"].set_06.flow_04 > :nth-child(n + 5):nth-child(-n + 6) {
    grid-column: 1/2;
  }

  [class*="grid_03b"][class*="flow"].set_06 > :first-child,
  [class*="grid_03c"].set_06.flow_03 > :nth-child(n + 2):nth-child(-n + 3),
  [class*="grid_03c"].set_06.flow_04 > :nth-child(n + 2):nth-child(-n + 4) {
    grid-column: 3/4;
  }

  [class*="grid_03"][class*="flow"].set_06:not(.grid_03):not([class*="3a"]):not([class*="3b"]) > :first-child,
  [class*="grid_03b"].set_06.flow_01 > :nth-child(n + 2):nth-child(-n + 3),
  [class*="grid_03b"].set_06.flow_02 > :nth-child(n + 2):nth-child(-n + 4),
  [class*="grid_03"].set_06.flow_03:not([class*="03c"]):not([class*="03d"]) > :nth-child(n + 2):nth-child(-n + 5),
  [class*="grid_03b"].set_06.flow_04 > :nth-child(2) {
    grid-column: 2/3;
  }
  
  [class*="grid_03d"].set_06.flow_01 > :nth-child(n + 2):nth-child(-n + 3),
  [class*="grid_03d"].set_06.flow_02 > :nth-child(n + 2):nth-child(-n + 4) {
    grid-column: 3/4;
  }

  /* [grid_04].set_04, [grid_04].set_04.flow_01 */
  [class*="grid_04c"].set_04 > :first-child,
  [class*="grid_04d"].set_04 > :nth-child(2),
  [class*="grid_04a"].set_04.flow_01 > :nth-child(3),
  [class*="grid_04c"].set_04.flow_01 > :nth-child(3) {
    grid-column: 2/3;
  }
  [class*="grid_04b"].set_04 > :first-child,
  [class*="grid_04d"].set_04 > :first-child,
  [class*="grid_04a"].set_04.flow_01 > :nth-child(2),
  [class*="grid_04c"].set_04.flow_01 > :nth-child(2) {
    grid-column: 3/4;
  }
  [class*="grid_04b"].set_04 > :nth-child(2),
  [class*="grid_04a"].set_04.flow_01 > :first-child,
  [class*="grid_04c"].set_04.flow_01 > :first-child {
    grid-column: 4/5;
  }
  /* [grid_04].set_05 */
  [class*="grid_04"].set_05 > :first-child {
    grid-column: 1/5;
  }
  [class*="grid_04"].set_05 > :nth-child(n + 2):nth-child(-n + 5) {
    grid-row: 2/3;
  }
  /* [grid_04].set_06  */
  [class*="grid_04"].set_06:not([class*="flow_"])
    > :nth-child(n + 1):nth-child(-n + 2),
  [class*="grid_04"].set_06.flow_01 > :nth-child(n + 1):nth-child(-n + 2),
  [class*="grid_04"].set_06.flow_02 > :nth-child(n + 1):nth-child(-n + 2) {
    grid-row: 1/3;
  }
  /* grid_04.set_06 */
  [class*="grid_04d"].set_06.flow_02 > :first-child {
    grid-column: 2/3;
  }
  [class*="grid_04b"].set_06.flow_01 > :first-child,
  [class*="grid_04b"].set_06.flow_02 > :first-child,
  [class*="grid_04d"].set_06.flow_02 > :nth-child(2) {
    grid-column: 3/4;
  }
  [class*="grid_04b"].set_06.flow_01 > :nth-child(2),
  [class*="grid_04b"].set_06.flow_02 > :nth-child(2),
  [class*="grid_04d"].set_06.flow_02 > :nth-child(n + 3):nth-child(-n + 4) {
    grid-column: 4/5;
  }

  [class*="grid_04d"].set_06 > :nth-child(4) {
    grid-row: 2/3;
  }
  /* Borders */
  /* grid_02  */
  [class*="rule"][class*="grid_02"]:not([class*="flow"]) > :first-child,
  [class*="rule"][class*="grid_02a"].flow_01:not([class*="set"]) > :first-child, 
  [class*="rule"][class*="grid_02b"].flow_01:not([class*="set"]) > :nth-child(2),
  [class*="rule"][class*="grid_02"]:not([class*="02a"]).flow_01 > :first-child,
  [class*="rule"][class*="grid_02b"][class*="set"][class*="flow"]:not([class*="set_05"]) > :last-child,
  [class*="rule"][class*="grid_02"].set_05.flow_02 > :nth-child(2n),
  [class*="rule"][class*="grid_02"].set_05.flow_03:not([class*="02b"]) > :nth-child(2n),
  [class*="rule"][class*="grid_02b"].set_05.flow_03 > :nth-child(2n+3),
  [class*="rule"][class*="grid_02"].set_05.flow_04 > :nth-child(-n+2),
  [class*="rule"][class*="grid_02"].set_05.flow_07 > :nth-child(n+4) {
    border-right: var(--rule) solid var(--border-color);;
  }
  [class*="rule"][class*="grid_02a"].flow_01 > :first-child, 
  [class*="rule"][class*="grid_02"][class*="set"][class*="flow"]:not([class*="set_05"]) > :last-child,
  [class*="rule"][class*="grid_02"].set_05.flow_05 > :nth-child(-n+2),
  [class*="rule"][class*="grid_02"].set_05.flow_06 > :nth-child(n+4) {
    border-left: var(--rule) solid var(--border-color);;
    padding-left: var(--adj_bit);
  }
  
  [class*="grid_02a"]:not([class*="set"]).flow_01 > :first-child,
  [class*="grid_02"].set_03.flow_01 > :last-child,
  [class*="grid_02b"].set_04.flow_02 > :last-child,
  [class*="grid_02b"].set_03.flow_03 > :last-child,
  [class*="grid_02b"].set_03.flow_05 > :last-child {
    border-left: 0 !important;
  }
  [class*="grid_02b"]:not([class*="set"]).flow_01 > :first-child,
  [class*="grid_02"].set_03.flow_01 > :last-child,
  [class*="grid_02"].set_03.flow_02 > :last-child,
  [class*="grid_02b"].set_03.flow_04 > :last-child {
    border-right: 0 !important;
  }
  [class*="grid_02b"].set_04.flow_01 > :last-child {
    border-left: 0 !important;
    border-right: 0 !important;
  }

  /* grid_03 */
 
  [class*="rule"][class*="grid_03"]:not([class*="set"]) > :nth-child(2),
  [class*="rule"][class*="grid_03"]:not([class*="_03c"]):not([class*="_03d"]).set_03.flow_01 > :nth-child(2),
  [class*="rule"][class*="grid_03c"].set_03.flow_01 > :nth-child(3), 
  [class*="rule"][class*="grid_03d"].set_03.flow_01 > :nth-child(3),
  [class*="rule"][class*="grid_03"].set_04:not([class*="flow"]) > :nth-child(3),
  [class*="rule"][class*="grid_03"]:not([class*="_03c"]):not([class*="_03d"]).set_04.flow_01 > :nth-child(3),
  [class*="rule"][class*="grid_03c"].set_04.flow_01 > :nth-child(2), 
  [class*="rule"][class*="grid_03d"].set_04.flow_01 > :nth-child(2),
  [class*="rule"][class*="grid_03"][class*="flow"]:not([class*="flow_01"]):not([class*="flow_06"]):not([class*="03a"]):not([class*="03b"]).set_04 > :first-child,
  [class*="rule"][class*="grid_03b"].set_04.flow_02 > :last-child,
  [class*="rule"].grid_03.set_04.flow_06 > :nth-child(3),
  [class*="rule"][class*="grid_03a"].set_04.flow_06 > :nth-child(3),
  [class*="rule"][class*="grid_03b"].set_04.flow_06 > :last-child,
  [class*="rule"][class*="grid_03"]:not([class*="03a"]):not([class*="03b"]):not(.grid_03).set_05.flow_01 > :first-child,
  [class*="rule"][class*="grid_03"]:not([class*="03c"]):not([class*="03d"]).set_05.flow_03 > :nth-child(4),
  [class*="rule"].grid_03.set_05.flow_04 > :nth-child(4),
  [class*="rule"][class*="grid_03a"].set_05.flow_04 > :nth-child(4),
  [class*="rule"][class*="grid_03b"].set_05.flow_04 > :nth-child(2),
  [class*="rule"][class*="grid_03c"][class*="flow"].set_06 > :first-child,
  [class*="rule"][class*="grid_03d"][class*="flow"].set_06 > :first-child {
    border-right: var(--rule) solid var(--border-color);;
    border-left: var(--rule) solid var(--border-color);;
    padding-left: var(--adj_bit);
  }
  [class*="rule"][class*="grid_03"]:not([class*="flow"]).set_03 > :nth-child(-n+2),
  [class*="rule"][class*="grid_03"]:not([class*="flow"]).set_05 > :nth-child(-n+3),
  [class*="rule"][class*="grid_03b"].set_04.flow_03 > :last-child,
  [class*="rule"][class*="grid_03"]:not([class*="03c"]):not([class*="03d"]).set_04.flow_04 > :nth-child(2),
  [class*="rule"].grid_03.set_04.flow_05 > :nth-child(-n+2), 
  [class*="rule"][class*="grid_03a"].set_04.flow_05 > :nth-child(-n+2),
  [class*="rule"][class*="grid_03"]:not([class*="03a"]):not([class*="03b"]).set_04.flow_06 > :nth-child(3),
  [class*="rule"].grid_03.set_05.flow_01 > :nth-child(-n+3),
  [class*="rule"][class*="grid_03a"].set_05.flow_01 > :nth-child(-n+3),
  [class*="rule"][class*="grid_03b"].set_05.flow_02 > :nth-child(n+3),
  [class*="rule"][class*="grid_03d"].set_05.flow_02 > :not(:nth-child(n+3):nth-child(-n+4)),
  [class*="rule"][class*="grid_03c"].set_05.flow_03 > :nth-child(4), 
  [class*="rule"][class*="grid_03d"].set_05.flow_03 > :nth-child(4),
  [class*="rule"][class*="grid_03c"].set_05.flow_04 > :first-child,
  [class*="rule"][class*="grid_03d"].set_05.flow_04 > :first-child,
  [class*="rule"].grid_03.set_06:not(.flow_02) > :nth-child(-n+3),
  [class*="rule"][class*="grid_03a"].set_06:not(.flow_02) > :nth-child(-n+3),
  [class*="rule"][class*="grid_03"]:not([class*="03b"]).set_06.flow_02 > :first-child,
  [class*="rule"][class*="grid_03b"].set_06.flow_02 > :nth-child(n+5),
  [class*="rule"][class*="grid_03b"].set_06.flow_03 > :last-child {
    border-right: var(--rule) solid var(--border-color);;
  }
  [class*="rule"][class*="grid_03"].set_04.flow_02:not([class*="03c"]):not([class*="03d"]) > :last-child,
  [class*="rule"][class*="grid_03b"].set_05.flow_01 > :nth-child(-n+3),
  [class*="rule"].grid_03.set_05.flow_02 > :nth-child(n+3), 
  [class*="rule"][class*="grid_03a"].set_05.flow_02 > :nth-child(n+3),
  [class*="rule"][class*="grid_03c"].set_05.flow_02 > :not(:nth-child(n+3):nth-child(-n+4)),
  [class*="rule"][class*="grid_03c"][class*="flow"]:not([class*="flow_01"]).set_05 > :last-child, 
  [class*="rule"][class*="grid_03d"][class*="flow"]:not([class*="flow_01"]).set_05 > :last-child,
  [class*="rule"][class*="grid_03b"].set_06:not(.flow_02) > :nth-child(-n+3),
  [class*="rule"][class*="grid_03"]:not([class*="03a"]):not(.grid_03).set_06.flow_02 > :first-child,
  [class*="rule"].grid_03.set_06.flow_02 > :nth-child(n+5),
  [class*="rule"][class*="grid_03a"].set_06.flow_02 > :nth-child(n+5),
  [class*="rule"].grid_03.set_06.flow_03 > :last-child,
  [class*="rule"][class*="grid_03a"].set_06.flow_03 > :last-child {
    border-left: var(--rule) solid var(--border-color);;
    padding-left: var(--adj_bit);
  }
  [class*="rule"][class*="grid_03b"]:not([class*="flow"]).set_03 > :nth-child(-n+2),
  [class*="rule"].grid_03.set_04.flow_03 > :last-child,
  [class*="rule"][class*="grid_03a"].set_04.flow_03 > :last-child,
  [class*="rule"][class*="grid_03b"][class*="flow"]:not(.flow_01):not(.flow_02):not(.flow_06).set_04 > :first-child,
  [class*="rule"][class*="grid_03b"].set_04.flow_05 > :nth-child(-n+2),
  [class*="rule"][class*="grid_03"]:not([class*="03a"]):not([class*="03b"]):not(.grid_03).set_04.flow_06 > :last-child {
    border-right: 0;
    border-left: var(--rule) solid var(--border-color);;
    padding-left: var(--adj_bit);
  }
  [class*="grid_03a"].set_04.flow_01 > :first-child,
  [class*="grid_03a"].set_04.flow_06 > :first-child {
    border-right: 0 !important;
  }
  [class*="flow"].grid_03.set_04 > :first-child {
    border-left: 0 !important;
  }
  .grid_03.set_06.flow_03 > :nth-child(n + 2):nth-child(-n + 5),
  [class*="grid_03a"].set_06.flow_03  > :nth-child(n + 2):nth-child(-n + 5),
  [class*="grid_03b"].set_06.flow_03 > :nth-child(n + 2):nth-child(-n + 5),
  .grid_03.set_06.flow_04 > :nth-child(n + 3):nth-child(-n + 5),
  [class*="grid_03a"].set_06.flow_04  > :nth-child(n + 3):nth-child(-n + 5),
  [class*="grid_03b"].set_06.flow_04 > :nth-child(n + 3):nth-child(-n + 5) {
    border-left: 0 !important;
    border-right: 0 !important;
  }

  /* grid_04 */
  [class*="rule"][class*="grid_04"]:not(.flow_01.set_05) > :not(:last-child),
  [class*="rule"][class*="grid_04b"].set_04 > :last-child,
  [class*="grid_04a"].set_04.flow_01 > :last-child,
  [class*="grid_04c"].set_04.flow_01 > :last-child ,
  [class*="rule"][class*="grid_04"].set_05 > :not(:last-child),
  [class*="rule"][class*="grid_04b"][class*="flow"].set_06 > :nth-child(2n+4),
  [class*="rule"][class*="grid_04d"].set_06.flow_02 > :last-child {
    border-right: var(--rule) solid var(--border-color);;
  }
  [class*="grid_04b"].set_04 > :nth-child(2),
  [class*="grid_04a"].set_04.flow_01 > :first-child, 
  [class*="grid_04c"].set_04.flow_01 > :first-child,
  [class*="grid_04"].set_05 > :first-child,
  [class*="grid_04"].set_06:not([class*="04d"]):not([class*="04b"][class*="flow"]) > :nth-child(4),
  [class*="grid_04d"].set_06:not(.flow_02) > :nth-child(5),
  [class*="grid_04b"][class*="flow"].set_06 > :nth-child(2),
  [class*="grid_04d"].set_06.flow_02 > :nth-child(n+3):nth-child(-n+4) {
    border-right: 0 !important;
  }



}

@media (max-width: 29.999rem) {
  [class*="grid_0"] {
    width: 100%;
    display: block;
    padding: 1.5rem;
  }
  [class*="grid_0"] > * {
    width: 100%;
    margin-bottom: 1.5rem;
  }
}
.print-only {
  display: none;
}
@media print {
  .print-only {
    display: block;
  }
  *,
  * > *:not(span) {
    display: block !important;
    width: 100% !important;
    border-left-width: 0 !important;
    border-right-width: 0 !important;
  }
  header,
  footer,
  form,
  … {
    display: none;
  }
  article {
    width: 100% !important;
    padding: 0 !important;
    margin: 0 !important;
  }
  a:after {
    content: "(" attr(href) ")";
  }
  body {
    background-color: transparent;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    color: black;
    margin-bottom: 10pt;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    break-after: avoid;
  }
  img,
  svg {
    break-inside: avoid;
  }
  table {
    break-inside: avoid-page;
  }
  img {
    height: auto;
  }

  h1,
  .title {
    font-size: 20.27pt;
    line-height: 25pt;
  }
  h2,
  .subtitle {
    font-size: 18.02pt;
    line-height: 22pt;
  }
  h3 {
    font-size: 16.02pt;
    line-height: 20pt;
  }
  h4 {
    font-size: 14.24pt;
    line-height: 18pt;
  }
  h5 {
    font-size: 12.66pt;
    line-height: 16pt;
  }
  h6 {
    font-size: 11.25pt;
    line-height: 14pt;
  }
  p {
    font-size: 10pt;
    line-height: 12.5pt;
    widows: 3;
  }
}
@media print {
  main {
    display: flex;
  }
  img {
    height: auto;
  }
}
@media print and (orientation: portrait) {
  main {
    flex-direction: column;
  }
  img {
    width: 100%;
  }
}
@media print and (orientation: landscape) {
  main {
    flex-direction: row;
  }
  img {
    width: 50%;
  }
}
